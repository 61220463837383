const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/pages/Index/IndexPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'base',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/impersonate',
        name: 'impersonate',
        component: () => import('@/pages/Index/ImpersonatePage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'blank',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/patients',
        name: 'patients',
        component: () => import('@/pages/Patient/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/main',
        name: 'main',
        component: () => import('@/pages/Index/MainPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'register',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/pages/Index/RegisterPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import('@/pages/Patient/GeneralCalendar.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'Kalendar',
        }
    },
    {
        path: '/register/region',
        name: 'register.region',
        component: () => import('@/pages/Index/RegisterRegionPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'register',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/patients/:id/show',
        name: 'patients.show',
        component: () => import('@/pages/Patient/ShowPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'content-with-sidebar',
            navigation: 'info',
            title: 'Bemor',
        }
    },
    {
        path: '/patients/:id/orders/create',
        name: 'patients.orders.create',
        component: () => import('@/pages/Patient/OrderPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'content-with-sidebar',
            navigation: 'order_create',
            title: 'Bemor',
        }
    },
    {
        path: "*",
        component: () => import('@/pages/Page/PageNotFound.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/Registerer/LoginPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'register',
            title: 'Kirish',
        }
    },
    {
        path: '/not-auth',
        name: 'not_auth',
        meta: {
            layout: 'content',
            requiresAuth: false,
            title: '401',
        },
        component: () => import('@/pages/Page/PageNotAllowed.vue'),
    },
    {
        path: '/privacy-policy',
        name: 'privacy_policy',
        component: () => import('@/pages/Page/PrivacyPolicyPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'blank',
            title: 'Maxfiylik siyosati',
        }
    },


]

export default routes